import React, { useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const InputUserForm = () => {
  const baseURL = useContext(ApiContext);
  const { auth } = useContext(AuthContext);
  const token = auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = Boolean(id);

  const [formData, setFormData] = useState({
    username: '',
    name: '',
    email: '',
    password: '',
    satker: '',
    instansi: '',
    access_level: '',
  });
  const [errors, setErrors] = useState({ username: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [satkerOptions, setSatkerOptions] = useState([{ value: '', label: '-- Pilih Opsi --' }]);
  const [instansiOptions, setInstansiOptions] = useState([{ value: '', label: '-- Pilih Opsi --' }]);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(`${baseURL}/api/user/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const user = response.data.user;
      setFormData({
        username: user.username || '',
        name: user.name || '',
        email: user.email || '',
        password: '',
        satker: user.satker || '',
        instansi: user.instansi || '',
        access_level: user.access_level || '',
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [baseURL, id, token]);

  const setDefaultDropdownOptions = useCallback((satkerError = '-- Pilih Access Level Terlebih Dahulu --', instansiError = '-- Pilih Access Level Terlebih Dahulu --') => {
    setSatkerOptions([{ value: '', label: satkerError }]);
    setInstansiOptions([{ value: '', label: instansiError }]);
  }, []);

  const updateSatkerOptions = useCallback((satkerData = [], accessLevel) => {
    let filteredData = satkerData;
    
    // Filter out DIVHUBINTER for access levels 1 and 3
    if ([1, 3].includes(Number(accessLevel))) {
      filteredData = satkerData.filter(polda => polda.polda !== "DIVHUBINTER");
    }

    setSatkerOptions([
      { value: '', label: '-- Pilih POLDA --' },
      ...filteredData.map((polda) => ({
        value: polda.polda,
        label: polda.polda,
      })),
    ]);
  }, []);

  const updateInstansiOptions = useCallback((instansiResponse) => {
    const instansiData =
      [0, 1, 2, 3].includes(Number(formData.access_level))
        ? instansiResponse?.data?.satuan_unit || []
        : instansiResponse?.data?.btnclo || [];

    setInstansiOptions([
      { value: '', label: '-- Pilih Satuan --' },
      ...instansiData.map((item) => ({
        value: item.instansi || item.btnclo,
        label: item.instansi || item.btnclo,
      })),
    ]);
  }, [formData.access_level]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        let poldaEndpoint;
        let instansiEndpoint;
        const accessLevel = Number(formData.access_level);
  
        // Determine endpoints based on access level
        if ([1, 3].includes(accessLevel)) {
          poldaEndpoint = `${baseURL}/api/dropdown_polda`;
          instansiEndpoint = `${baseURL}/api/dynamic_polres_plbn/${encodeURIComponent(formData.satker || '')}`;
        } else if (accessLevel === 2) {
          poldaEndpoint = `${baseURL}/api/dropdown_satker_lain`;
        } else if ([4, 5].includes(accessLevel)) {
          poldaEndpoint = `${baseURL}/api/v1/btnclo/dropdown_polda`;
          instansiEndpoint = `${baseURL}/api/v1/btnclo/dynamic_btnclo/${encodeURIComponent(formData.satker || '')}`;
        } else {
          setDefaultDropdownOptions();
          return;
        }
  
        // Fetch SATKER/POLDA data
        const satkerResponse = await axios.get(poldaEndpoint, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        // Handle access level 2 (satker should be extracted from "satker" key)
        if (accessLevel === 2) {
          const satkerData = satkerResponse?.data?.satker || []; // Fix here
          setSatkerOptions([
            { value: '', label: '-- Pilih Satker --' },
            ...satkerData.map((item) => ({
              value: item.instansi, // Use instansi instead of polda
              label: item.instansi,
            })),
          ]);
  
          // Set instansi to "Satker" and update the dropdown
          setInstansiOptions([{ value: 'Satker', label: 'Satker' }]);
          setFormData((prev) => ({ ...prev, instansi: 'Satker' }));
          return;
        }
  
        // Update SATKER options with filtering for access levels 1/3
        updateSatkerOptions(satkerResponse?.data?.polda, accessLevel);
  
        // Fetch INSTANSI data if SATKER is selected
        if (formData.satker) {
          const instansiResponse = await axios.get(instansiEndpoint, {
            headers: { Authorization: `Bearer ${token}` },
          });
          updateInstansiOptions(instansiResponse);
        } else {
          setInstansiOptions([{ 
            value: '', 
            label: '-- Pilih POLDA Terlebih Dahulu --' 
          }]);
        }
  
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
        setDefaultDropdownOptions('Error Loading POLDA Data', 'Error Loading Satuan Data');
      }
    };

    fetchDropdownOptions();
  }, [formData.access_level, formData.satker, token, baseURL, updateSatkerOptions, updateInstansiOptions, setDefaultDropdownOptions]);

  useEffect(() => {
    if (isEditMode) {
      fetchUserData();
    }
  }, [isEditMode, fetchUserData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validateField = (name, value) => {
    if (name === 'username' && value.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, username: 'Username tidak boleh kosong' }));
    } else if (name === 'password' && value.trim() === '' && (!isEditMode || formData.password.trim() !== '')) {
      setErrors((prevErrors) => ({ ...prevErrors, password: 'Password tidak boleh kosong' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
  };

  const togglePasswordVisibility = () => setShowPassword((prevState) => !prevState);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const payload = { ...formData };
        if (isEditMode && formData.password.trim() === '') delete payload.password;

        const apiEndpoint = isEditMode
          ? `${baseURL}/api/update_user/${id}`
          : `${baseURL}/api/register`;

        await axios.post(apiEndpoint, payload, { headers: { Authorization: `Bearer ${token}` } });
        navigate('/admin/adminuser');
      } catch (error) {
        console.error('Error saving user:', error.response ? error.response.data : error.message);
      }
    }
  };

  const validateForm = () => {
    let hasErrors = false;
    if (formData.username.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, username: 'Username tidak boleh kosong' }));
      hasErrors = true;
    }
    if (formData.password.trim() === '' && (!isEditMode || formData.password.trim() !== '')) {
      setErrors((prevErrors) => ({ ...prevErrors, password: 'Password tidak boleh kosong' }));
      hasErrors = true;
    }
    return !hasErrors;
  };

  const handleCancel = () => navigate('/admin/adminuser');

  return (
    <div className="mt-8">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
            <div className="text-lg font-bold">{isEditMode ? 'Edit User' : 'Input User'}</div>
            <div className="flex flex-col gap-6 px-4">
              <FormInput label="Username" type="text" name="username" value={formData.username} handleChange={handleChange} errors={errors.username} />
              <FormInput label="Fullname" type="text" name="name" value={formData.name} handleChange={handleChange} />
              <FormInput label="Email" type="email" name="email" value={formData.email} handleChange={handleChange} />
              <PasswordInput showPassword={showPassword} togglePasswordVisibility={togglePasswordVisibility} value={formData.password} handleChange={handleChange} errors={errors.password} />
              <FormSelect label="Access Level" name="access_level" value={formData.access_level} handleChange={handleChange} options={getAccessLevelOptions()} />
              <FormSelect label="SATKER" name="satker" value={formData.satker} handleChange={handleChange} options={satkerOptions} />
              <FormSelect label="Satuan Unit" name="instansi" value={formData.instansi} handleChange={handleChange} options={instansiOptions} />
            </div>
            <div className="flex flex-row justify-end gap-3 mt-8">
              <button type="button" onClick={handleCancel} className="bg-red text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Cancel
              </button>
              <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const FormInput = ({ label, type, name, value, handleChange, errors }) => (
  <div className="flex flex-col">
    <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
    <div className="mt-2">
      <input
        type={type}
        name={name}
        id={name}
        autoComplete="off"
        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors ? 'ring-red' : ''}`}
        value={value}
        onChange={handleChange}
      />
      {errors && <span className="text-red text-sm">{errors}</span>}
    </div>
  </div>
);

const PasswordInput = ({ showPassword, togglePasswordVisibility, value, handleChange, errors }) => (
  <div className="flex flex-col relative">
    <label htmlFor="password" className="block text-base leading-6 text-gray">Password</label>
    <div className="mt-2 relative">
      <input
        type={showPassword ? 'text' : 'password'}
        name="password"
        id="password"
        autoComplete="new-password"
        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors ? 'ring-red' : ''}`}
        value={value}
        onChange={handleChange}
      />
      <FontAwesomeIcon
        icon={showPassword ? faEyeSlash : faEye}
        className="text-blue h-5 w-5 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
        onClick={togglePasswordVisibility}
      />
      {errors && <span className="text-red text-sm">{errors}</span>}
    </div>
  </div>
);

const FormSelect = ({ label, name, value, handleChange, options }) => (
  <div className="flex flex-col">
    <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
    <div className="mt-2">
      <select
        id={name}
        name={name}
        autoComplete="off"
        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
        value={value}
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  </div>
);

const getAccessLevelOptions = () => [
  { value: '', label: '-- Pilih Opsi --' },
  { value: '0', label: '0 - Developer' },
  { value: '1', label: '1 - Admin' },
  { value: '2', label: '2 - VVIP' },
  { value: '3', label: '3 - Operator IMBAS' },
  { value: '4', label: '4 - Koordinator BTNCLO' },
  { value: '5', label: '5 - Anggota BTNCLO' },
];

export default InputUserForm;
