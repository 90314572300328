import React, { useEffect, useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const Laporan = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const satker = auth?.user?.instansi;
    const navigate = useNavigate();

    const [laporan, setLaporan] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedLaporanId, setSelectedLaporanId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationLinks, setPaginationLinks] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('semua');
    const [loading, setLoading] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState('');

    const toUpperCase = (str) => {
        return str ? str.toUpperCase() : '';
    };

    const fetchLaporan = useCallback(async (page = 1, search = '') => {
        setLoading(true);
        try {
            let endpoint = '';
            
            if (search) {
                // For search, include both search term and page number in the query
                switch (selectedFilter) {
                    case 'personal':
                        endpoint = `/api/search_laporan_biasa/${search}?page=${page}`;
                        break;
                    case 'khusus':
                        endpoint = `/api/search_laporan_khusus/${search}?page=${page}`;
                        break;
                    default:
                        endpoint = `/api/search_laporan_semua/${search}?page=${page}`;
                        break;
                }
            } else {
                // For pagination without search
                switch (selectedFilter) {
                    case 'personal':
                        endpoint = `/api/laporan_biasa?page=${page}`;
                        break;
                    case 'khusus':
                        endpoint = `/api/laporan_khusus?page=${page}`;
                        break;
                    default:
                        endpoint = `/api/laporan_semua?page=${page}`;
                        break;
                }
            }

            // Construct the full URL
            const url = `${baseURL}${endpoint}`;
            
            // Make the API request
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            
            const laporanData = response.data.laporan;

            if (search) {
                setLaporan(laporanData.data); // Set the search results data
                setPaginationLinks(laporanData.links); // Set pagination links
                setCurrentPage(laporanData.current_page);
                setTotalPages(laporanData.last_page);
            } else {
                const { data, links, current_page, last_page } = laporanData;
                setLaporan(data);
                setPaginationLinks(links);
                setCurrentPage(current_page);
                setTotalPages(last_page);
            }
        } catch (error) {
            console.error('There was an error fetching the report data!', error);
        } finally {
            setLoading(false);
        }
    }, [baseURL, token, selectedFilter]);


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchLaporan(1, searchKeyword);
        }, 300); // 300ms delay

        return () => clearTimeout(delayDebounceFn);
    }, [fetchLaporan, searchKeyword, selectedFilter]);

    const deleteLaporan = useCallback(async (laporanId) => {
        try {
            await axios.delete(`${baseURL}/api/delete_laporan/${laporanId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            window.location.reload(); // Reload the page after deletion
        } catch (error) {
            console.error('There was an error deleting the report!', error);
        }
    }, [baseURL, token]);

    const handleEditClick = (laporanId) => {
        navigate(`editlaporan/${laporanId}`);
    };

    const handleDeleteClick = (laporanId) => {
        setSelectedLaporanId(laporanId);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        console.log('Confirm delete for laporan ID:', selectedLaporanId);
        deleteLaporan(selectedLaporanId);
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            fetchLaporan(page, searchKeyword);
        }
    };

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setCurrentPage(1);
        fetchLaporan(1, searchKeyword);
    };

    const handleSearchChange = (event) => {
        setSearchKeyword(event.target.value);
        setCurrentPage(1);
    };

    const renderPagination = () => (
        <div className="flex justify-center mt-4 gap-2 flex-wrap">
            {currentPage > 1 && (
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            )}
            {paginationLinks
                .filter(link => !link.label.includes('Previous') && !link.label.includes('Next'))
                .map((link, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(parseInt(link.label))}
                        className={`mx-1 px-4 py-2 rounded-lg flex items-center justify-center ${link.active ? 'bg-blue text-white' : 'bg-gray-dark text-white'}`}
                    >
                        {link.label}
                    </button>
                ))}
            {currentPage < totalPages && (
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            )}
        </div>
    );
    

    const DeleteConfirmationModal = ({ handleConfirmDelete, handleCancelDelete }) => (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-box bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <div className="text-lg font-semibold mb-4">Apakah anda ingin menghapus data ini?</div>
                <div className="flex flex-row justify-between">
                    <button
                        onClick={handleConfirmDelete}
                        className="bg-green text-white px-4 py-2 rounded-lg"
                    >
                        Ya
                    </button>
                    <button
                        onClick={handleCancelDelete}
                        className="bg-red text-white px-4 py-2 rounded-lg"
                    >
                        Tidak
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="h-auto">
            <div className="flex flex-row justify-between items-center">
                <div className="font-mp-bold text-xl text-gray-light">Laporan</div>
            </div>
            <div className="mt-8">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                        <div className="flex flex-row justify-between gap-4 laptop:w-full">
                            <div className="pb-4 bg-white text-black-light">
                                <div className="flex flex-col relative mt-1 gap-4">
                                    <input
                                        type="text"
                                        id="search"
                                        placeholder="Search..."
                                        value={searchKeyword}
                                        onChange={handleSearchChange}
                                        className="block w-full rounded-md border-0 px-2 py-1 mr-20 shadow-sm ring-1 ring-black-light placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-black-light sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            {(auth?.user?.access_level !== "2") ? (
                                <Link to="/laporan/addLaporan" className="bg-blue hover:bg-green text-[white] flex justify-between items-center h-10 w-auto p-4 gap-2 rounded-lg">
                                    <FontAwesomeIcon icon={faPlus} className="text-white h-4 w-4" />
                                    <div className="hidden laptop:flex">Input Laporan</div>
                                </Link>
                            ) : null
                            }
                        </div>

                        <div className="mt-6 text-base">
                            <div className="flex flex-col w-3/4 gap-3 mb-4 laptop:flex-row laptop:mb-4">
                                <div
                                    onClick={() => handleFilterChange('semua')}
                                    className={`flex justify-between items-center h-10 w-auto p-4 gap-2 rounded-full cursor-pointer ${
                                        selectedFilter === 'semua' ? 'bg-green text-white' : 'bg-blue text-white'
                                    }`}
                                >
                                    <div className="">Semua</div>
                                </div>
                                <div
                                    onClick={() => handleFilterChange('personal')}
                                    className={`flex justify-between items-center h-10 w-auto p-4 gap-2 rounded-full cursor-pointer ${
                                        selectedFilter === 'personal' ? 'bg-green text-white' : 'bg-blue text-white'
                                    }`}
                                >
                                    <div className="">Laporan {satker}</div>
                                </div>
                                <div
                                    onClick={() => handleFilterChange('khusus')}
                                    className={`flex justify-between items-center h-10 w-auto p-4 gap-2 rounded-full cursor-pointer ${
                                        selectedFilter === 'khusus' ? 'bg-green text-white' : 'bg-blue text-white'
                                    }`}
                                >
                                    {(auth?.user?.access_level !== "3") ? (
                                        <div className="">Laporan Khusus IMBAS CEPAT</div>
                                    ) : (
                                        <div className="">Laporan Khusus {satker}</div>
                                    )}
                                </div>
                            </div>
                            {loading ? (
                                <div className="text-center py-4">Loading data...</div>
                            ) : (
                                <div className="min-w-full overflow-x-auto">
                                    <div className="overflow-y-auto">
                                        <table className="min-w-full table-auto divide-y divide-gray-dark">
                                            <thead className="bg-gray-light">
                                                <tr>
                                                    <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NO</th>
                                                    <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">JUDUL LAPORAN</th>
                                                    <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">INSTANSI</th>
                                                    <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">WAKTU DIBUAT</th>
                                                    <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-dark">
                                                {laporan.length > 0 ? (
                                                    laporan.map((report, index) => (
                                                        <tr key={report.id}>
                                                            <td className="px-5 py-2 whitespace-normal">{(currentPage - 1) * laporan.length + index + 1}</td>
                                                            <td className="px-5 py-4 whitespace-normal">
                                                                <Link to={`/laporan/${report.id}`}>{toUpperCase(report.judulLaporan)}</Link>
                                                            </td>
                                                            <td className="px-5 py-2 whitespace-normal">{report.instansi}</td>
                                                            <td className="px-5 py-2 whitespace-normal">{new Date(report.created_at).toLocaleDateString('en-GB')}</td>
                                                            <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                                            {(auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1) ||
                                                                (auth?.user?.instansi === report.instansi) ? (
                                                                    <>
                                                                        <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto" title="Edit">
                                                                            <FontAwesomeIcon
                                                                                icon={faEdit}
                                                                                className="text-white h-4 w-4"
                                                                                onClick={() => handleEditClick(report.id)}
                                                                            />
                                                                        </button>
                                                                        <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto" title="Delete">
                                                                            <FontAwesomeIcon
                                                                                icon={faTrash}
                                                                                className="text-white h-4 w-4"
                                                                                onClick={() => handleDeleteClick(report.id)}
                                                                            />
                                                                        </button>
                                                                    </>
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5" className="text-center py-4">
                                                            No data available.
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                        {renderPagination()}
                    </div>
                </div>
            </div>

            {showModal && (
                <DeleteConfirmationModal
                    handleConfirmDelete={handleConfirmDelete}
                    handleCancelDelete={handleCancelDelete}
                />
            )}
        </div>
    );
};

export default Laporan;
