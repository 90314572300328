import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const PolsekForm = () => {
  const baseURL = useContext(ApiContext);
  const { auth } = useContext(AuthContext);
  const token = auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = Boolean(id);

  const [poldaOptions, setPoldaOptions] = useState([]);
  const [polresOptions, setPolresOptions] = useState([]);
  const [formData, setFormData] = useState({
    polda: '',
    polres: '',
    polsek: '',
    jabatan: '',
    nama: '',
    kontak: '',
  });

  // Fetch POLDA options
  useEffect(() => {
    const fetchPoldaOptions = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/dropdown_polda`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Filter out "DIVHUBINTER"
        const filteredPolda = response.data.polda.filter(
          (polda) => polda.polda !== 'DIVHUBINTER'
        );

        setPoldaOptions([
          { value: '', label: '-- Pilih POLDA --' },
          ...filteredPolda.map((polda) => ({
            value: polda.polda,
            label: polda.polda,
          })),
        ]);
      } catch (error) {
        console.error('Error fetching POLDA options:', error);
      }
    };

    fetchPoldaOptions();
  }, [token, baseURL]);

  // Fetch POLRES options dynamically based on selected POLDA
  useEffect(() => {
    const fetchPolresOptions = async () => {
      if (!formData.polda) {
        setPolresOptions([{ value: '', label: '-- Pilih POLDA Terlebih Dahulu --' }]);
        return;
      }
  
      try {
        const response = await axios.get(`${baseURL}/api/dynamic_polres_plbn/${encodeURIComponent(formData.polda || '')}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
  
        const satuanUnitData = response.data.satuan_unit || [];
  
        // Filter based on selected POLDA and remove those containing "PLBN"
        const filteredPolres = satuanUnitData
          .filter(unit => unit.satuan === formData.polda) // Match the selected POLDA
          .filter(unit => !unit.instansi.includes("PLBN")); // Exclude "PLBN"
  
        setPolresOptions([
          { value: '', label: '-- Pilih POLRES --' },
          ...filteredPolres.map(unit => ({
            value: unit.instansi, // Using 'instansi' as the value
            label: unit.instansi, // Using 'instansi' as the label
          })),
        ]);
      } catch (error) {
        console.error('Error fetching POLRES options:', error);
        setPolresOptions([{ value: '', label: 'Error Loading POLRES' }]);
      }
    };
  
    fetchPolresOptions();
  }, [token, baseURL, formData.polda]);

  // Fetch POLSEK data when in edit mode
  useEffect(() => {
    if (!isEditMode) return;

    const fetchPolsekData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/polsek/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setFormData({
          polda: response.data.polsek.polda || '',
          polres: response.data.polsek.polres || '',
          polsek: response.data.polsek.polsek || '',
          jabatan: response.data.polsek.jabatan || '',
          nama: response.data.polsek.nama || '',
          kontak: response.data.polsek.kontak || '',
        });
      } catch (error) {
        console.error('Error fetching POLSEK data:', error);
      }
    };

    fetchPolsekData();
  }, [id, isEditMode, token, baseURL]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      ...(name === 'polda' ? { polres: '' } : {}), // Reset POLRES if POLDA changes
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiEndpoint = isEditMode
      ? `${baseURL}/api/update_polsek/${id}`
      : `${baseURL}/api/store_polsek`;

    try {
      const response = await axios.post(apiEndpoint, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log('Polsek saved successfully:', response.data);
      navigate(-1);
    } catch (error) {
      console.error('Error saving POLSEK:', error.response?.data || error.message);
    }
  };

  // Handle form cancel
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="mt-8">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
            <div className="text-lg font-bold">{isEditMode ? 'Edit Polsek' : 'Input Polsek'}</div>
            <div className="flex flex-col gap-6 px-4">
              <FormSelect label="POLDA" name="polda" value={formData.polda} handleChange={handleChange} options={poldaOptions} />
              <FormSelect label="POLRES" name="polres" value={formData.polres} handleChange={handleChange} options={polresOptions}/>
              <FormInput label="Nama POLSEK" type="text" name="polsek" value={formData.polsek} handleChange={handleChange} />
              <FormInput label="Jabatan" type="text" name="jabatan" value={formData.jabatan} handleChange={handleChange} />
              <FormInput label="Nama" type="text" name="nama" value={formData.nama} handleChange={handleChange} />
              <FormInput label="Kontak" type="text" name="kontak" value={formData.kontak} handleChange={handleChange} />
            </div>
            <div className="flex flex-row justify-end gap-3 mt-8">
              <button type="button" onClick={handleCancel} className="bg-red text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Cancel
              </button>
              <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const FormInput = ({ label, type, name, value, handleChange }) => (
  <div className="flex flex-col">
    <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
    <div className="mt-2">
      <input
        type={type}
        name={name}
        id={name}
        autoComplete="off"
        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-dark focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
        value={value}
        onChange={handleChange}
      />
    </div>
  </div>
);

const FormSelect = ({ label, name, value, handleChange, options }) => (
  <div className="flex flex-col">
    <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
    <div className="mt-2">
      <select
        id={name}
        name={name}
        autoComplete="given-name"
        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
        value={value}
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  </div>
);

export default PolsekForm;
